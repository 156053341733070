<template>
  
    <section class="banner banner-full">
        <img class="bg-banner" :src="path + banner" alt="Banner">
    </section>

    <!-- banner tag -->
    <section class="banner-tag">
        <span class="text-right text-white"> {{ caption }} </span>
    </section>

</template>

<script>
export default {
    name: 'Banner',
    props: [
        'banner',
        'caption',
        'path'
    ]
}
</script>

<style scoped>
    .bg-banner {
        width: 100%;
        height: 100%;
        text-align:center;
        object-position:center;
    }

    .banner {
        /*padding: 250px 0 150px;*/
        /*padding: 300px;*/
        position: relative;
        margin-top: 120px;
    }

    /*@media (max-width: 1024px) {
        .banner {
            padding: 100px 0;
        }
    }*/

    .banner-full .bg-banner {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .banner-full .bg-banner {
        height: auto;
        margin-top :30px;
    }

    @media (max-width: 767px) {
        .banner-full .bg-banner {
            margin-bottom: 0px;
            height: auto;
            margin-top: 0px;
        }
    }

    .banner-tag {
        background: #0158a7;
        padding-bottom: 30px;
        border-bottom: 5px solid #ea2e23;
    }

    .banner-tag span {
        float: right;
        text-transform: uppercase;
        font-weight: 200;
        color: #fff;
        padding-right: 35px;
        padding-top: 3px;
    }
</style>